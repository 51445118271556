import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Select from 'common/src/components/Select';
import Container from 'common/src/components/UI/Container';
import ContactSections from '../Contact';
import FooterWrapper, { List, ListItem, BgImageWrapper } from './footer.style';
import AppImage from 'common/src/assets/image/crypto/footerapp.svg';
import PlaystoreImage from 'common/src/assets/image/crypto/footerplay.svg';
import FooterBG from 'common/src/assets/image/crypto/footer-bg.svg';
import Logo from 'common/src/assets/image/crypto/logoNotClear.png';

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }
        Language_NAMES {
          label
          value
        }
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      {/*  <ContactSections /> */}

      <BgImageWrapper>
        {/* <Image src={FooterBG} alt="Footer background" /> */}
      </BgImageWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box className="row mainRow" {...row}>
          <Box {...colOne}>
            <Box className="imageWrapper">
              <Image src={Logo} />
            </Box>
            <Heading
              content="Best Finance is dedicated to making homeownership faster and simpler, with superior customer support along the way."
              {...titleStyle}
            />
            {/* <Select
              options={Data.cryptoJson.Language_NAMES}
              placeholder="English"
              className="Language_search_select"
              aria-label="Language_search_input"
            /> */}
            {/*}
            <Heading
              className="appDownload"
              content="Download The App"
              {...titleStyle}
              /> 
            <Box className="imageWrapper">
              <a href="https://www.apple.com/ios/app-store/">
                <Image src={AppImage} alt="App Image" />
              </a>
              <a href="https://play.google.com/store/apps">
                <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
              </a>
            </Box> */}
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.cryptoJson.menuWidget.map((widget) => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item) => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a className="ListItem" href={item.url}>
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row} style={{ paddingTop: '15px' }}>
          <Text
            content="Best Finance | Powered by Nexa Mortgage NMLS#1660690 - For licensing information, go to www.nmlsconsumeraccess.org.
			Best Finance is not a lender, but retains certain mortgage broker licenses to comply with various
			state requirements. 600 Cleveland Street Suite 248, Clearwater, FL 33755
			Copyright 2024 @Best Finance.  "
            className="copyRightText"
          />
          {/* <Text
            content="Best Finance Home Equity Line is available in FL, with more states to come."
            className="copyRightText"
          /> */}
          <Text content="Equal Housing Opportunity" className="copyRightText" />
          <Text
            content="This site is not authorized by the New York State Department of Financial Services. No mortgage solicitation activity or loan applications for properties located in the State of New York can be facilitated through this site."
            className="copyRightText"
          />
          <Text
            content="The Best Finance Home Equity Line of Credit is an open-end product where the full loan amount (minus the origination fee) will be 100% drawn at the time of origination. The initial amount funded at origination will be based on a fixed rate; however, this product contains an additional draw feature. As the borrower repays the balance on the line, the borrower may make additional draws during the draw period. If the borrower elects to make an additional draw, the interest rate for that draw will be set as of the date of the draw and will be based on an Index, which is the Prime Rate published in the Wall Street Journal for the calendar month preceding the date of the additional draw, plus a fixed margin. Accordingly, the fixed rate for any additional draw may be higher than the fixed rate for the initial draw."
            className="copyRightText"
          />
          <Text
            content="Approval may be granted in five minutes but is ultimately subject to verification of income and employment, as well as verification that your property is in at least average condition with a property condition report. Five business day funding timeline assumes closing the loan with our remote online notary. Funding timelines may be longer for loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing."
            className="copyRightText"
          />
          <Text
            content="To check the rates and terms you qualify for, we will conduct a soft credit pull that will not affect your credit score. However, if you continue and submit an application, we will request your full credit report from one or more consumer reporting agencies, which is considered a hard credit pull and may affect your credit."
            className="copyRightText"
          />
          <Text
            content="Best Finance's Home Equity Line and Mortgage products require that you pledge your home as collateral, and you could lose your home if you fail to repay."
            className="copyRightText"
          />
          <Text
            content="Our loan amounts range from a minimum of $15,000 to a maximum of $2,000,000. For properties located in AK, the minimum loan amount is $25,001. Your maximum loan amount may be lower than $400,000, and will ultimately depend on your home value and equity at the time of application. We determine home value and resulting equity through independent data sources and automated valuation models."
            className="copyRightText"
          />
          <Text
            content="All savings calculations are estimates. Savings totals are calculated using the average mortgage balance, average interest rates, and average HELOC amount applied for, and subtracting the difference paid over the life of the estimated loan terms. Actual savings will depend on several factors determined at the time of application and may be less than estimated."
            className="copyRightText"
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#000000',
    fontSize: ['13px', '14px', '14px', '16px', '16px'],
    fontWeight: '400',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
    marginRight: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
